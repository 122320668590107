



















































import { Component, Vue } from 'vue-property-decorator';
import { AuthStore } from '../store/modules/auth.store';

@Component({
  apollo: {},
})
export default class LoginView extends Vue {
  isLoggingIn = false;
  loginSuccessful = false;
  email: string = '';
  password: string = '';

  created() {}

  async login() {
    if (!this.isLoggingIn) {
      this.isLoggingIn = true;
      try {
        await AuthStore.login({ email: this.email, password: this.password });
        this.$router.push({ name: 'home', replace: true });
      } catch (e) {
        this.isLoggingIn = false;
        this.$notify({
          group: 'main',
          type: 'error',
          title: 'Error',
          text: 'Invalid Credentials',
        });
      }
    }
  }
}
